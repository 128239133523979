<template>
  <div style="height: 100vh; display: flex">
    <!-- 左侧菜单 -->
    <div
      :style="{
        width: isCollapse ? '64px' : '250px',
        transition: 'all 0.3s ease',
      }"
    >
      <el-menu
        default-active="1"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
        class="el-menu-vertical-demo"
        style="height: 100%; width: 100%"
        :collapse="isCollapse"
      >
        <h2 style="text-align: center; color: white">YonoGames</h2>
        <h3 style="text-align: center; color: white">Backend System</h3>
        <div
          style="color: #fff; padding: 10px 25px"
          @click="isCollapse = !isCollapse"
        >
          <i
            v-if="isCollapse === false"
            class="el-icon-arrow-left"
            style="margin-right: 5px"
          ></i>
          <i v-else class="el-icon-arrow-right"></i>
          <span v-if="isCollapse === false">Close Menu</span>
        </div>
        <!-- <el-menu-item index="0" @click="isCollapse = !isCollapse">
          <i v-if="isCollapse === false" class="el-icon-arrow-left"></i>
          <i v-else class="el-icon-arrow-right"></i>
          <span slot="title">Close Menu</span>
        </el-menu-item> -->
        <el-menu-item index="1" @click="open(1)">
          <i class="el-icon-menu"></i>
          <span slot="title">User Manage</span>
        </el-menu-item>
        <el-menu-item index="2" @click="open(2)">
          <i class="el-icon-s-management"></i>
          <span slot="title">Report Manage</span>
        </el-menu-item>
        <!-- <el-menu-item index="3" @click="open(3)">
            <i class="el-icon-s-management"></i>
            <span slot="title">Report Manage</span>
          </el-menu-item> -->
      </el-menu>
    </div>
    <!-- 右侧内容 -->
    <div style="height: 100vh; flex: 1; width: 100vh; overflow: auto">
      <div
        style="
          display: flex;
          justify-content: flex-start;
          height: 40px;
          padding-left: 10px;
        "
      >
        <el-popover placement="top-start" trigger="hover">
          <p>User：{{ user }}</p>
          <p>Score：{{ money }}</p>
          <p>Share：{{ share }}%</p>
          <el-button
            style="margin: 10px 10px 0 0; width: 100%"
            @click="logOuts()"
            type="danger"
            >LoginOut</el-button
          >
          <p
            slot="reference"
            style="
              margin: 0;
              transform: translateY(10px);
              font-style: italic;
              font-weight: bolder;
              margin-right: 20px;
            "
          >
            Welcome! {{ user }} Score：{{ money }} Current share：{{ share }}%
            Points payable：{{ payPoint }} Reflux Points：{{
              backpoint
            }}
            Deserve Point：{{ discount }}
          </p>
        </el-popover>
      </div>
      <!-- 标签页内容 -->
      <el-tabs
        v-if="isShow == true"
        v-model="editableTabsValue"
        type="card"
        editable
        @edit="handleTabsEdit"
      >
        <el-tab-pane
          :key="item.name"
          v-for="item in editableTabs"
          :label="item.title"
          :name="item.name"
        >
          <component
            :is="item.component"
            @contentChanged="refreshContent"
          ></component>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import UserVue from "./UserVue.vue";
import QueryVue from "./QueryVue.vue";
import CreateVue from "./CreateVue.vue";
import { getUserInfo } from "@/api/api";
export default {
  data() {
    return {
      username: "",
      avatar: "",
      oldPassword: "",
      newPassword: "",
      editableTabs: [], // 初始化为空数组
      editableTabsValue: "1",
      isShow: true,
      dialogVisible: false,
      componentsList: {
        1: "UserVue",
        2: "QueryVue",
        3: "CreateVue",
      },
      currentView: "UserVue",
      user: "",
      money: "",
      isCollapse: false,
      share: "",
      payPoint: "",
      backpoint: "",
      discount: "",
    };
  },
  methods: {
    refreshContent() {
      this.getUserInfos();
    },
    screeneeWidth() {
      if (window.innerWidth < 768) {
        this.dialogWidth = "80%";
        this.isCollapse = true;
      } else {
        this.dialogWidth = "30%";
      }
    },
    open(code) {
      let existingTab = this.editableTabs.find(
        (tab) => tab.name === this.componentsList[code]
      );
      if (!existingTab) {
        this.currentView = this.componentsList[code];
        let menuName = {
          1: "User Manage",
          2: "Report Manage",
          3: "CreateVue",
        };
        this.editableTabs.push({
          title: menuName[code],
          name: this.componentsList[code],
          component: this.componentsList[code],
        });
        this.editableTabsValue = this.componentsList[code];
      } else {
        this.editableTabsValue = existingTab.name;
      }
    },
    handleTabsEdit(targetName, action) {
      if (action === "add") {
        let newTabName = ++this.editableTabs.length;
        this.editableTabs.push({
          title: "New Tab", // 这里可以根据需要设置新的标签页标题
          name: newTabName,
          component: "NewTabComponent", // 你需要替换为实际的组件名称
        });
        this.editableTabsValue = newTabName;
      }
      if (action === "remove") {
        let tabs = this.editableTabs;
        let activeName = this.editableTabsValue;
        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }
        this.editableTabsValue = activeName;
        this.editableTabs = tabs.filter((tab) => tab.name !== targetName);
      }
    },
    logOuts() {
      localStorage.setItem("isLogin", false);
      this.$message.success("LogOut Success!Refrush Page");
      setTimeout(() => {
        this.$router.push("/login");
      }, 2000);
    },
    getUserInfos() {
      const id = localStorage.getItem("id");
      getUserInfo(id).then((res) => {
        if (res.data.code === 200) {
          this.backpoint = res.data.msg.backpoint;
          this.payPoint = res.data.msg.paypoint;
          this.money = res.data.msg.money;
          this.share = res.data.msg.proportion;
          this.discount = res.data.msg.discount;
        } else {
          this.$message.error("Get User Info Failed!");
        }
      });
    },
  },
  created() {
    this.open(1);
    this.user = localStorage.getItem("username");
    this.money = localStorage.getItem("money");
    this.share = localStorage.getItem("proportion");
    this.payPoint = localStorage.getItem("paypoint");
    this.screeneeWidth();
    this.getUserInfos();
  },
  components: {
    UserVue,
    QueryVue,
    CreateVue,
  },
};
</script>

<style>
body {
  margin: 0;
}
.custom-scroll {
  height: 100vh;
  overflow-y: auto;
}

.custom-scroll::-webkit-scrollbar {
  width: 8px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #3498db;
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
</style>
