import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://api.7771101.com/",
});

// 登录接口
export const Login = (username, password) => {
  return axiosInstance.post("/admin/login", {
    username: username,
    password: password,
  });
};

// 获取代理列表
export const getagent = (username) => {
  return axiosInstance.post("/admin/getagent", {
    username: username,
  });
};

// 新增代理
export const addNewAgent = (
  user,
  username,
  password,
  nickname,
  mode,
  proportion
) => {
  return axiosInstance.post("/admin/addNewAgent", {
    user: user,
    username: username,
    password: password,
    nickname: nickname,
    mode: mode,
    proportion: proportion,
  });
};

// 设定用户状态
export const setUserStatus = (id, status) => {
  return axiosInstance.post("/admin/setUserStatus", {
    id: id,
    status: status,
  });
};

// 请求玩家数据
export const getplayerList = (page, list) => {
  return axiosInstance.post("/admin/getplayerList", {
    page: page,
    list: list,
  });
};

// 代理上下分 type 1 上分 type 2 下分
export const uploadScore = (username, agent, score, type, id) => {
  return axiosInstance.post("/admin/uploadScore", {
    username: username,
    agent: agent,
    score: score,
    type: type,
    id: id,
  });
};

// 新增代理
export const addNewMember = (
  user,
  username,
  password,
  nickname,
  mode,
  proportion
) => {
  return axiosInstance.post("/admin/addNewMember", {
    user: user,
    username: username,
    password: password,
    nickname: nickname,
    mode: mode,
    proportion: proportion,
  });
};

// 加载用户积分
export const loadScore = (username) => {
  return axiosInstance.post("/admin/loadScore", {
    username: username,
  });
};

// 获取当前用户剩余积分
export const getUserScore = (username) => {
  return axiosInstance.post("/admin/getUserScore", {
    username: username,
  });
};

// 获取当前用户应缴纳分数
export const getUserProportionPay = (id) => {
  return axiosInstance.post("/admin/getUserProportionPay", {
    id: id,
  });
};

// 标识用户渠道
export const changeUserChannel = (username) => {
  return axiosInstance.post("/admin/changeUserChannel", {
    username: username,
  });
};

// 游戏内增加金币
export const addCoin = (token, phone, bonus, id) => {
  return axiosInstance.post("/admin/addCoin", {
    token: token,
    phone: phone,
    bonus: bonus,
    id: id,
  });
};

// 游戏内减少金币
export const downCoin = (token, username, coin, id) => {
  return axiosInstance.post("https://adm.7771101.com/admin/payment/coinDown", {
    token: token,
    username: username,
    coin: coin,
    id: id,
  });
};

// 删除代理或会员
export const deleteUser = (username) => {
  return axiosInstance.post("/admin/delUser", {
    username: username,
  });
};

// 获取用户信息
export const getUserInfo = (id) => {
  return axiosInstance.post("/admin/getUserInfo", {
    id: id,
  });
};

// 代理结算
export const agentSettle = (id, aid) => {
  return axiosInstance.post("/admin/agentSettle", {
    id: id,
    aid: aid,
  });
};

// USDT支付
export const USDTPay = (amount) => {
  return axiosInstance.post("/UsdtPay/orderpay", {
    amount: amount,
  });
};
