<template>
  <div>
    <div
      style="
        display: flex;
        justify-content: center;
        transform: translateY(100px);
      "
    >
      <el-card style="width: 300px">
        <p style="margin: 5px">Backend System Login Page</p>
        <el-form>
          <el-input
            placeholder="please input username"
            v-model="username"
          ></el-input>
          <el-input
            placeholder="please input password"
            style="margin-top: 10px"
            v-model="password"
          ></el-input>
          <el-button
            type="primary"
            style="margin-top: 10px; width: 100%"
            @click="logins"
            >Login</el-button
          >
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import { Login } from "@/api/api";
export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    logins() {
      Login(this.username, this.password).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("Login Successful!");
          localStorage.setItem("isLogin", true);
          localStorage.setItem("username", this.username);
          localStorage.setItem("money", res.data.data.money);
          localStorage.setItem("id", res.data.data.id);
          localStorage.setItem("username", res.data.data.username);
          localStorage.setItem("proportion", res.data.data.proportion);
          localStorage.setItem("paypoint", res.data.data.paypoint);
          this.$router.push("/");
        } else {
          this.$message.error("Login Failed," + res.data.msg);
        }
      });
    },
  },
  created() {},
};
</script>

<style></style>
