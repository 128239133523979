<template>
  <div>
    <h1>WebSocket Client</h1>
  </div>
</template>

<script>
// import CryptoJS from "./script/crypto-js";
// import msgpack from "./script/msgpack.min.js";
import * as msgpack from "@msgpack/msgpack";
import CryptoJS from "crypto-js";

export default {
  data() {
    return {
      address: "ws9951.br55777.com",
      handlers: {}, // 去掉了下划线
      curtime: 0, // 去掉了下划线
      ws: null, // 去掉了下划线
      idx: 0, // 去掉了下划线
      token: null,
      autoConnectCount: 0, // 去掉了下划线
      subid: null,
      uid: null,
    };
  },
  mounted() {
    this.curtime = new Date().getTime(); // 初始化时间戳
    this.autoTravellerLogin(); // 自动游客登录
  },
  methods: {
    connect(serverAddress, callback) {
      let url = `wss://${serverAddress}/ws`;
      this.ws = new WebSocket(url);

      this.ws.onopen = () => {
        console.log("WebSocket 连接已打开");

        this.autoConnectCount = 0;

        if (callback) callback();
      };

      this.ws.onmessage = (event) => {
        console.log("收到消息:", event.data);
        this.handleResponeData(event.data);
      };

      this.ws.onerror = (error) => {
        console.error("WebSocket 通信发生错误:", error);
      };

      this.ws.onclose = () => {
        console.log("WebSocket 连接已关闭");
        if (this.address === "ws9951.br55777.com" && this.uid) {
          this.userinfo();
        }
      };
    },
    reqLogin(nickname, pwd, loginType, accesstoken, loginExData, token) {
      this.connect(this.address, () => {
        const req = this.constructLoginMsg(
          nickname,
          pwd,
          loginType,
          accesstoken,
          loginExData,
          token
        );
        req.bundleid = "com.yono.games.free";
        req.deviceid = "0";
        this.send(req);
      });
    },
    handleResponeData(msgData) {
      const decodeArrayBuff = (arrayBuf) => {
        const dataview = new DataView(arrayBuf);
        const headSize = 8;
        const uint8Array = new Uint8Array(arrayBuf.byteLength - headSize);

        for (let i = 0; i < uint8Array.length; i++) {
          uint8Array[i] = dataview.getUint8(headSize + i);
        }

        const msgDic = msgpack.decode(uint8Array);
        const parsedMsg = JSON.parse(msgDic);
        console.log(parsedMsg);

        if (parsedMsg.c === 1) {
          this.uid = parsedMsg.uid;
          this.subid = parsedMsg.subid;
          this.token = parsedMsg.token;
        }
      };

      if (window.FileReader && msgData instanceof Blob) {
        const reader = new FileReader();
        reader.addEventListener("loadend", () => {
          decodeArrayBuff(reader.result);
        });
        reader.readAsArrayBuffer(msgData);
      } else {
        decodeArrayBuff(msgData);
      }
    },
    constructLoginMsg(
      nickname,
      pwd,
      loginType,
      accesstoken,
      loginExData,
      token
    ) {
      return {
        c: 1,
        user: nickname,
        passwd: pwd,
        app: 17,
        v: "1.0.0.0",
        t: 1,
        accessToken: accesstoken,
        platform: "H5_Windows",
        deviceToken: "0",
        phone: "Web_Windows",
        token: token,
        bwss: 0,
        LoginExData: 1,
        language: 2,
        client_uuid: new Date().getTime() + Math.random(1, 9999999),
      };
    },
    isConnect() {
      const state = this.ws && this.ws.readyState === WebSocket.OPEN;
      console.log("连接状态:", this.ws.readyState);
      console.log("连接状态:", state);
      return state;
    },
    autoTravellerLogin() {
      let localNickname = "Guest" + Math.floor(Math.random() * 9001) + 999;
      const token =
        new Date().getTime() + "_" + Math.floor(Math.random() * 99999999) + 1;
      this.reqLogin(localNickname, localNickname, 1, "", 1, token);
    },
    send(msgDic) {
      if (this.isConnect()) {
        if (typeof msgDic === "string") {
          msgDic = JSON.parse(msgDic);
        }
        msgDic.c_ts = new Date().getTime();
        if (msgDic.c === 1) {
          msgDic.x = CryptoJS.MD5(
            msgDic.c_ts.toString() + "hero888"
          ).toString();
        }
        msgDic.c_idx = this.idx++;
        msgDic.language = 2;
        console.log(msgDic);

        const bodyData = this.pack(JSON.stringify(msgDic));
        const headData = this.generateHead(bodyData);
        const uint8Array = this.linkHeadBody(headData, bodyData);
        this.ws.send(uint8Array);
        return true;
      } else {
        console.log("WebSocket 未连接！");
      }
      return false;
    },
    pack(msgDic) {
      return msgpack.encode(msgDic);
    },
    generateHead(bodyData) {
      const msgLen = bodyData.length;
      const len = this.jsToCByShort(msgLen);
      const time = this.jsToCByInt(Math.floor(this.curtime / 1000));
      const checkSum = this.getCheckSum(bodyData, msgLen);

      return `${len}${checkSum}${time}`;
    },
    jsToCByShort(value) {
      const low1 = Math.floor(value / 256);
      const low2 = Math.floor(value % 256);
      return String.fromCharCode(low1, low2);
    },
    jsToCByInt(value) {
      const low1 = Math.floor(value / (256 * 256 * 256));
      const low2 = Math.floor(value / (256 * 256)) % 256;
      const low3 = Math.floor(value / 256) % 256;
      const low4 = Math.floor(value % 256);
      return String.fromCharCode(low1, low2, low3, low4);
    },
    getCheckSum(bodyData, msgLen) {
      let src =
        msgLen < 128
          ? this.srcSum(bodyData, msgLen)
          : this.srcSum(bodyData, 128);
      return this.jsToCByShort(src);
    },
    srcSum(strData, len) {
      let sum = 65535;
      for (let i = 0; i < len; i++) {
        const d = strData[i];
        sum = sum ^ d;
        sum = (sum >> 1) ^ (sum & 1 ? 0x70b1 : 0);
      }
      return sum;
    },
    linkHeadBody(headData, bodyDataBuf) {
      const headLen = headData.length;
      const bodyLen = bodyDataBuf.length;
      const uint8Array = new Uint8Array(headLen + bodyLen);
      for (let i = 0; i < headLen; i++) {
        uint8Array[i] = headData.charCodeAt(i);
      }
      for (let i = 0; i < bodyLen; i++) {
        uint8Array[headLen + i] = bodyDataBuf[i];
      }
      return uint8Array;
    },
    userinfo() {
      this.connect("ws9959.br55777.com", () => {
        const req = {
          c: 2,
          uid: this.uid,
          openid: "",
          server: "node",
          subid: this.subid,
          token: this.token,
          deviceid: "0",
          appver: "1.0.0",
        };
        this.send(req);
        setTimeout(() => {
          this.send({
            c: 134,
            phone: "666552222100555",
            code: "111111",
            pwd: "111111",
            name: "asdfasdfs",
            uid: this.uid,
          });
        }, 1000);
      });
    },
    bind() {
      this.address = "ws9959.br55777.com";
      this.connect(this.address);
    },
  },
};
</script>

<style scoped>
h1 {
  color: #2c3e50;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: normal;
}
</style>
