import Vue from "vue";
import Router from "vue-router";
import HomeVue from "@/components/HomeVue";
import LoginVue from "@/components/LoginVue";
import CreateVue from "@/components/CreateVue";

Vue.use(Router);

const router = new Router({
  mode: "hash", // 使用 HTML5 history 模式, 如果需要可以选择 'hash' 模式
  routes: [
    {
      path: "/",
      name: "HomeVue",
      component: HomeVue,
      meta: { requiresAuth: true },
    },
    {
      path: "/login",
      name: "LoginVue",
      component: LoginVue,
    },
    {
      path: "/create",
      name: "CreateVue",
      component: CreateVue,
    },
  ],
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("isLogin") === "true";

  // 如果用户试图访问需要认证的页面而未登录，则重定向到登录页面
  if (to.matched.some((record) => record.meta.requiresAuth) && !isLoggedIn) {
    next({ name: "LoginVue" });
  }
  // 如果用户已经登录并试图访问登录页面，则重定向到主页
  else if (to.path === "/login" && isLoggedIn) {
    next({ name: "HomeVue" });
  }
  // 否则，允许导航
  else {
    next();
  }
});

export default router;
