import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import axios from "axios";
import App from "./App.vue";
import TinyVue from "@opentiny/vue";

Vue.use(TinyVue);
Vue.use(ElementUI);

// 配置axios全局使用
Vue.prototype.$http = axios;

new Vue({
  router,
  el: "#app",
  render: (h) => h(App),
});
